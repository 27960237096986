<template>
  <div class="bg-white h-full w-full flex flex-col md:flex-col mt-5 mb-10 ml-3">
    <!-- Payment Gateway Methods - approved  --->
    <div v-if="businessPaymentGatewayMethods?.length > 0" class="mb-10 space-y-3">
      <div>
        <p class="font-bold">
          {{ $t("payment.settings.payment_provider.title") }}
        </p>
        <p class="text-gray-400">
          {{ $t("payment.settings.payment_provider.description") }}
        </p>
      </div>
      <app-alert-error v-if="errors.enabled" class="p-4 bg-error-badge" :message="errors.enabled[0]" />
      <div v-for="(
          [paymentMethodName, paymentMethodId], paymentMethod
        ) in Object.entries(PAYMENT_METHODS)" :key="paymentMethod">
        <div v-if="
          checkBusinessHasPaymentMethods(
            businessPaymentGatewayMethods,
            paymentMethodId
          ) || paymentMethodId == PAYMENT_METHODS.CREDIT_CARD
        ">
          <p class="text-xs text-gray-500 py-2">
            {{ paymentMethodName?.replaceAll("_", " ") }}
          </p>
          <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-5">
            <card-payment-provider v-for="businessPaymentGatewayMethod in businessPaymentGatewayMethods"
              :key="businessPaymentGatewayMethod._id" :data="businessPaymentGatewayMethod" v-show="
                checkBusinessHasPaymentMethod(
                  businessPaymentGatewayMethod,
                  paymentMethodId
                )
              " />
          </div>
          <div v-if="
            !checkBusinessHasPaymentMethods(
              businessPaymentGatewayMethods,
              paymentMethodId
            ) && paymentMethodId == PAYMENT_METHODS.CREDIT_CARD
          " class="flex flex-col items-center pt-5 space-y-3">
            <span class="text-center w-2/3 text-sm mb-6">
              {{ $t("payment.settings.payment_provider.no_cc_info") }}
            </span>
            <app-button width="w-1/3" :showf70Icon="false" @click="openApplyForCCModal">
              {{ $t("payment.settings.payment_provider.apply_cc") }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="loading" class="flex justify-center mt-10">
      <app-icon-loading iconColor="primary" />
    </div>
    <div v-else class="flex flex-col items-center pt-5 space-y-3">
      <img class="w-32" src="@/assets/image/default-payment-provider.svg" />
      <span class="text-center font-bold">
        {{ $t("payment.settings.payment_provider.list_payment_provider") }}
      </span>
      <span class="text-center text-gray-400 w-1/3 text-sm">
        {{ $t("payment.settings.payment_provider.contact_support_desc") }}
      </span>
      <app-button width="w-1/3" :showf70Icon="false" @click="openTawkTo">
        {{ $t("general.contact_support") }}
      </app-button>
    </div>

    <app-modal :show="showApplyCCModal" @close="showApplyCCModal = false">
      <template v-slot:title>{{
          $t("payment.settings.payment_provider.apply_cc_dialog_title")
      }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("payment.settings.payment_provider.apply_cc_dialog_desc") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button @onClick="applyForCC()" :showf70Icon="false" :primary="false" type="button" class="
            sm:ml-3 sm:w-auto
            bg-approve
            hover:bg-success
            text-white
            outline-none
          ">
          {{ $t("payment.settings.payment_provider.apply_cc_proceed") }}
        </app-button>
      </template>
    </app-modal>
  </div>
</template>

<script>
import PAYMENT_GATEWAY_APPLICATION from '@/utils/const/payment_gateway_application_status'
import CardPaymentProvider from "../items/card-payment-provider.vue";
import PAYMENT_METHODS from "@/utils/const/payment_methods";
export default {
  data() {
    return {
      PAYMENT_METHODS: PAYMENT_METHODS,
      showApplyCCModal: false,
    };
  },
  components: {
    CardPaymentProvider,
  },
  beforeCreate() {
    this.$store.dispatch("paymentStore/getBusinessPaymentGatewayMethod");
  },
  computed: {
    businessPaymentGatewayMethods() {
      return this.$store.getters[
        "paymentStore/business_payment_gateway_method"
      ];
    },

    loading() {
      return this.$store.getters["paymentStore/loading"];
    },

    errors() {
      return this.$store.getters["paymentStore/errors"];
    },

    message() {
      return this.$store.getters["paymentStore/message"];
    },
  },
  methods: {
    checkBusinessHasPaymentMethod(businessPaymentGatewayMethod, paymentMethodId) {
      let hasPaymentMethod = businessPaymentGatewayMethod?.payment_gateway_method_application?.payment_gateway_method?.payment_method_id == paymentMethodId
      let hasPaymentGatewayApproved = this.checkPaymentGatewayApplicationStatus(businessPaymentGatewayMethod?.payment_gateway_method_application)
      return hasPaymentMethod && hasPaymentGatewayApproved && businessPaymentGatewayMethod.default
    },

    checkBusinessHasPaymentMethods(businessPaymentGatewayMethods, paymentMethodId) {
      return businessPaymentGatewayMethods.find(
        (businessPaymentGatewayMethod) =>
          businessPaymentGatewayMethod?.payment_gateway_method_application?.payment_gateway_method?.payment_method_id == paymentMethodId &&
          this.checkPaymentGatewayApplicationStatus(businessPaymentGatewayMethod?.payment_gateway_method_application) &&
          businessPaymentGatewayMethod.default
      )
    },

    checkPaymentGatewayApplicationStatus(payment_gateway_application, status = PAYMENT_GATEWAY_APPLICATION.APPROVED) {
      return payment_gateway_application?.payment_gateway_application_status_id == status;
    },

    openTawkTo() {
      window.open(
        "https://tawk.to/chat/"+process.env.VUE_APP_TAWKTO_PROPERTY_ID+"/"+process.env.VUE_APP_TAWKTO_WIDGET_ID,
        "_blank"
      );
    },

    openApplyForCCModal() {
      this.showApplyCCModal = true;
    },

    async applyForCC() {
      this.showApplyCCModal = false;

      const applyForCC = await this.$store.dispatch("paymentStore/applyForCC");

      if (applyForCC == null) {
        this.$notify(
          {
            group: "error",
            title: this.message,
          },
          5000
        );
        return;
      }

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.settings.payment_provider.apply_cc_sent"),
        },
        5000
      );
    },
  },
};
</script>