<template>
  <app-layout>
    <div class="container mx-auto py-3 px-5">
      <app-tab :tabs="tabs" @tab-changed="selectTab">
        <PaymentProvider v-if="select_tab_key == 'payment_provider'" />
      </app-tab>
    </div>
  </app-layout>
</template>

<script>
import PaymentProvider from "./payment-provider-page.vue";
export default {
  data() {
    return {
      select_tab_key: null,
    };
  },
  components: {
    PaymentProvider,
  },
  mounted() {
    this.selectTab(this.tabs[0].key);
  },
  computed: {
    tabs() {
      return [
        // { key: "setting_1", title: "Settings 1" },
        // { key: "setting_2", title: "Settings 2" },
        {
          key: "payment_provider",
          title: this.$t("payment.settings.payment_provider.tab_title"),
        },
      ];
    },
  },
  methods: {
    selectTab(tab_key) {
      this.select_tab_key = tab_key;
    },
  },
};
</script>